// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDB6V4mZ2Eoj1Wp9l-2b6zehsSwKTMefF4",
  authDomain: "whisky-wednesday.firebaseapp.com",
  projectId: "whisky-wednesday",
  storageBucket: "whisky-wednesday.appspot.com",
  messagingSenderId: "14920718230",
  appId: "1:14920718230:web:4c2df44e84901c2a38afb7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

// export const signInWithGoogle = () => {
//   signInWithPopup(auth, provider).then((result) => {
//     // consider moving logic into another file.  17:00 https://www.youtube.com/watch?v=vDT7EnUpEoo&ab_channel=PedroTech
//     console.log(result);
//     const name = result.user.displayName;
//     const email = result.user.email;
//     const profilePic = result.user.photoURL;

//     localStorage.setItem("name", name)
//     localStorage.setItem("email", email)
//     localStorage.setItem("profilePic", profilePic)
//   }).catch((error) => {
//     console.log(error);
//   });
// };