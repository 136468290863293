export const checkWednesday = () => {
  const today = new Date();
  return today.getDay() === 3; // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
};

export const getNextDayOfWeek = (date, dayOfWeek) => {
  // Wednesday => 3
  var resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7));

  return resultDate.toLocaleDateString();
};