import React from 'react'
import { useNavigate } from 'react-router-dom'
import { signInWithPopup } from "firebase/auth"
import { auth, provider, db } from "../Firebase"
import { collection, doc, setDoc } from "firebase/firestore";


function LoginButton({ setProfilePic, isAuth }) {
  let navigate = useNavigate();
  // const userCollectionRef = collection(db, "users");

  const signInWithGoogle = async () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        console.log(result);
        const uid = result.user.uid;
        const name = result.user.displayName;
        const email = result.user.email;
        const profilePic = result.user.photoURL;

        localStorage.setItem("name", name);
        localStorage.setItem("uid", uid);
        localStorage.setItem("email", email);
        localStorage.setItem("profilePic", profilePic);
        localStorage.setItem("isAuth", true);

        const docRef = doc(db, "users", uid);
        const userData = {
          uid: uid,
          name: name,
          email: email,
          profilePic: profilePic,
          lastLoginTime: new Date(),
          attendance: {
            1675696220000: true,
          },
        };
        console.log(userData);
        // setDoc with merge overwrites appropriate fields
        setDoc(docRef, userData, { merge: true });
        // setProfilePic(profilePic); // props fxn
        console.log("profilePic: ", profilePic);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const createUpdateUser = async (uid, userData) => {
  //   // // setDoc updates/creates
  //   await setDoc(doc(db, 'users', uid), userData)
  // };

  return (
    <button className="login-with-google-btn" onClick={signInWithGoogle}>
      Sign in with Google
    </button>
  );
}

export default LoginButton