import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import _ from "lodash";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const RatingsTable = ({ data }) => {
  // Extract column headers from data[1]
  console.log('data: ', data)
  const columnHeaders = data[1] 
    ? data[1].map((header) => header.trim()) // remove whitespace from headers
    : []; 

  const columnStyles = {
    '#': { initialWidth: 75 },
    Date: { initialWidth: 150, type: 'dateFormatted' },
    Name: { initialWidth: 250, filter: true },
    Joe: { initialWidth: 100, filter: false, type: 'numberWithOneDecimal' },
    John: { initialWidth: 100, filter: false, type: 'numberWithOneDecimal' },
    Jeff: { initialWidth: 100, filter: false, type: 'numberWithOneDecimal' },
    Justin: { initialWidth: 100, filter: false, type: 'numberWithOneDecimal' },
    Bill: { initialWidth: 100, filter: false, type: 'numberWithOneDecimal' },
    Jordan: { initialWidth: 100, filter: false, type: 'numberWithOneDecimal' },
    Chris: { initialWidth: 100, filter: false, type: 'numberWithOneDecimal' },
    Luke: { initialWidth: 100, filter: false, type: 'numberWithOneDecimal' },
    Average: { initialWidth: 100, filter: false, type: 'numberWithOneDecimal' },
    Style: { initialWidth: 200 },
    Price: { initialWidth: 75, filter: false, type: 'currencyRounded' },
    '' : { initialWidth: 0 },
  };

  // Create column definitions from column headers
  const columnTypes = {
    numberWithOneDecimal: {
      width: 100,
      filter: 'agNumberColumnFilter',
      valueFormatter: (params) =>
        !_.isNumber(params.value) ? '-' : params?.value?.toFixed(1),
    },
    dateFormatted: {
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const cellDate = new Date(cellValue);
          // Convert filter date to local date
          const filterDate = new Date(filterLocalDateAtMidnight);
          // Compare only dates (ignore time)
          cellDate.setHours(0, 0, 0, 0);
          filterDate.setHours(0, 0, 0, 0);
          if (cellDate < filterDate) {
            return -1;
          } else if (cellDate > filterDate) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      valueFormatter: (params) => {
        if (params.value) {
          const date = new Date(params.value);
          return date.toLocaleDateString('en-US');
        }
      },
    },
    currencyRounded: {
      valueFormatter: (params) =>
        !_.isNumber(params.value) ? '-' : `$${params?.value?.toFixed(0)}`,
    },
  }

  const columnDefs = columnHeaders
    ? columnHeaders.map((header) => {
      const customStyle = columnStyles[header] || {};
      const customDataType = customStyle.type;
      return {
        headerName: header,
        field: header.toLowerCase(),
        initialWidth: 100,
        sortable: true,
        resizable: true,
        type: customDataType,
        ...customStyle,
      };
    })
    : [];

  // Extract row data from data[2] until there are no ratings available in the row
  const rawRowData = data.slice(2).filter((row) => row[0] !== '');

  const rowData = rawRowData.map((row) => {
    const processedRow = row.slice(); // Copy row to avoid mutating original data
    for (let i = 3; i <= 8; i++) {
      if (isNaN(processedRow[i]) || processedRow[i] === '-') {
        processedRow[i] = null;
      }
    }
    return processedRow;
  })

  // Transform row data into the format AG-Grid expects
  const formattedRowData = rowData.map((row) => {
    const formattedRow = {};
    columnHeaders.forEach((header, index) => {
      formattedRow[header.toLowerCase()] = row[index];
    });
    return formattedRow;
  });

  return (
    <div id="ratings-table" className="ag-theme-alpine">
      <AgGridReact 
        columnDefs={columnDefs} 
        rowData={formattedRowData} 
        columnTypes={columnTypes}
      />
    </div>
  );
};

function Rate() {
  const sheetsApiUrl =
    'https://script.google.com/macros/s/AKfycbzhswVMLHnRU0_AlXFiGnoGOCYMlhodnUKp1mGwljx9mvw4lupVFeC7oMBPnW-23b0J/exec';
  const [sheetData, setSheetData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sheetNames, setSheetNames] = useState([
    'Year 1', 
    'Year 2',
    'Year 3',
    'Year 4',
    'Year 5',
    'Year 6',
    'Year 7',
    'Year 8',
    'Year 9',
  ]);
  const [selectedSheet, setSelectedSheet] = useState(sheetNames[7]);

  useEffect(() => {
    fetchSheetData();
  }, [selectedSheet]);

  const fetchSheetData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${sheetsApiUrl}?sheetName=${selectedSheet}`);
      // const response = await axios.get(sheetsApiUrl);
      setSheetData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="ratings-table-wrapper container">
      <select
        value={selectedSheet}
        onChange={(e) => setSelectedSheet(e.target.value)}
      >
        <option value="">Select a Sheet</option>
        {sheetNames.map((name) => (
          <option key={name} value={name}>
            {name}
          </option>
        ))}
      </select>
      <button onClick={fetchSheetData} disabled={isLoading}>
        {isLoading ? 'Refreshing Data...' : 'Refresh Data'}
      </button>
      {isLoading ? <p>Loading data...</p> : sheetData && <RatingsTable data={sheetData} />}
    </div>
  );
}

export default Rate;