import './App.scss';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from "react-router-dom";
import { auth } from './Firebase';
import { signOut } from 'firebase/auth';
import { useAuthState } from "react-firebase-hooks/auth";
import axios from 'axios';

// Pages
import Home from './pages/Home'; 
import Quorum from './pages/Quorum';
import Pour from './pages/Pour';
import Rate from './pages/Rate';

// Components
import { SlAvatar } from "@shoelace-style/shoelace/dist/react";
import LogoSVG from "./components/LogoSVG.jsx";
import LoginButton from './components/LoginButton.jsx';

// Shoelace - CDN defined below
import "@shoelace-style/shoelace/dist/themes/light.css";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path";

setBasePath(
  "https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.0.0/dist/"
  
);

function App() {
  const [isAuth, loading, error] = useAuthState(auth);
  const [profilePic, setProfilePic] = useState('');

  const sheetsApiUrl = 'https://script.google.com/macros/s/AKfycbzhswVMLHnRU0_AlXFiGnoGOCYMlhodnUKp1mGwljx9mvw4lupVFeC7oMBPnW-23b0J/exec'

  const handleSignOut = () => {
    signOut(auth)
    .then(() => {
      localStorage.clear();
      setProfilePic(''); // Update the state value
      window.location.pathname = "/"; // useNavigate will not work outside of the Router component
    })
  }

  useEffect(() => {
    if (isAuth && profilePic !== isAuth?.photoURL) {
      setProfilePic(isAuth?.photoURL); // Update the state value
    }
    // console.log("\nNAV profilePic: ", profilePic);
  }, [isAuth, profilePic]);

  async function get() {
    const res = await axios.get(sheetsApiUrl);
    console.log(res);
  }

  return (
    <Router>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
        <div className="container">
          <div className="navbar-brand me-auto" href="#">
            <Link to="/">
              <LogoSVG />
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDarkDropdown"
            aria-controls="navbarNavDarkDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
            {!isAuth ? (
              <ul className="navbar-nav ms-auto">
                <li className="nav-li">
                  <LoginButton 
                    // setProfilePic={setProfilePic} 
                    isAuth={isAuth} 
                  />
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav ms-auto">
                <li className="nav-item dropdown my-auto px-2 h3">
                  <Link to="/pour" className="nav-link">
                    Pour
                  </Link>
                </li>
                <li className="nav-item dropdown my-auto px-2 h3">
                  <Link to="/rate" className="nav-link">
                    Rate
                  </Link>
                </li>
                <li className="nav-item dropdown my-auto px-2 h3">
                  <Link to="/quorum" className="nav-link">
                    Quorum
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <div
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="mx-1">{isAuth?.email}</span>
                    <SlAvatar
                      label="User avatar"
                      shape="circle"
                      image={profilePic}
                      loading="lazy"
                      className="px-2"
                    />
                  </div>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-dark">
                    <li>
                      <div className="dropdown-item" 
                        onClick={handleSignOut}>
                        Sign Out
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Home isAuth={isAuth}/>} />
        <Route path="/quorum" element={<Quorum isAuth={isAuth} />} />
        <Route path="/pour" element={<Pour isAuth={isAuth} />} />
        <Route path="/rate" element={<Rate isAuth={isAuth} />} />
      </Routes>
    </Router>
  );
}

export default App;