import React, { useEffect, useState } from 'react';

const Countdown = () => {
  const targetDayOfWeek = 3; // Wednesday
  const targetHour = 20; // 8:00 PM

  const [remainingTime, setRemainingTime] = useState(null);
  const [isTimeToBeWithFriends, setIsTimeToBeWithFriends] = useState(false);

  useEffect(() => {
    const calculateRemainingTime = () => {
      const now = new Date();
      const currentDayOfWeek = now.getDay();
      const daysUntilTarget = (targetDayOfWeek + 7 - currentDayOfWeek) % 7;

      const targetDate = new Date();
      targetDate.setDate(now.getDate() + daysUntilTarget);
      targetDate.setHours(targetHour, 0, 0, 0);

      const timeDifference = targetDate.getTime() - now.getTime();
      setRemainingTime(timeDifference);
      
      const currentTime = now.getHours() * 100 + now.getMinutes();
      setIsTimeToBeWithFriends(
        currentTime >= 2010 && currentTime <= 2200 && currentDayOfWeek === 3
      );
    };

    const interval = setInterval(calculateRemainingTime, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (remainingTime === null) {
    return <div>Loading...</div>;
  }

  if (remainingTime <= 0) {
    return <div>It's Wednesday at 8:00 PM ET!</div>;
  }

  if (isTimeToBeWithFriends) {
    return <div>You should be with your friends!</div>;
  }

  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

  return (
    <div id='countdown' className='mb-2'>
      {/* <h1>Countdown to Wednesday at 8:00 PM ET</h1> */}
      <div className="time-unit mx-2">
        <div className="unit">{days}</div> 
        <div className="unit-title">days</div>
      </div>
      <div className="time-unit mx-2">
        <div className="unit">{hours}</div> 
        <div className="unit-title">hr</div>
      </div>
      <div className="time-unit mx-2">
        <div className="unit">{minutes}</div> 
        <div className="unit-title">min</div>
      </div>
      <div className="time-unit mx-2">
        <div className="unit">{seconds}</div> 
        <div className="unit-title">sec</div>
      </div>
    </div>
  );
};

export default Countdown;
