import React, { useState, useEffect, useRef } from 'react';
import { db } from '../Firebase';
import { collection } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { checkWednesday } from '../components/utilities'

import Countdown from '../components/Countdown';
import AudioPlayer from '../components/AudioPlayer';

function Pour({isAuth}) {
  const infoRef = collection(db, "info");
  const [infoData, loading, error] = useCollection(
    infoRef,
    { snapshotListenOptions: { includeMetadataChanges: true }, }
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  
  let isWednesday = checkWednesday();
  
  // useEffect(() => {
  //   isWednesday = checkWednesday()
  // }, [])
  

  return (
    <>
      <div className="container">
        <Countdown />
      </div>
      <section className="container mb-3">
        <AudioPlayer />
        {/* <h2 className="mt-2">{isWednesday ? "It\'s Wednesday my dudes!" : ""}</h2> */}
      </section>
    </>
  );
}

export default Pour