import React from 'react'

export default function LogoSVG() {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 459 283"
      width="80"
      height="100%"
    >
      <title>Whisky Wednesday</title>
      <g id="svg-logo">
        <g id="svg-shadow">
          <path
            id="svg-shadow-top"
            className="s0"
            fill="#7b7b7b"
            d="m77.7 145l58.6-141.5 1.2-3h3.1 3.8 3l1.2 3 52.2 125.6-2.4 5.8-5.2 12.7-50.8-122.2-57.9 140.3-1.2 2.9h-3.1-4.9-3.1l-1.2-2.9-65.5-158.7-2.7-6.5h7 5.1 3.1l1.2 3z"
          />
          <path
            id="svg-shadow-middle"
            className="s0"
            fill="#7b7b7b"
            d="m254.8 259.1l58.5-141.5 1.2-2.9h3.1 3.8 3l1.3 2.9 58.6 141.5 58.4-141.5 1.1-2.9h3.2 5.2 6.9l-2.6 6.4-65.5 158.7-1.3 3h-3-5-3.1l-1.2-3-58-140.3-57.9 140.3-1.2 3h-3.1-4.9-3.1l-1.2-3-29.1-70.6 7.6-18.5z"
          />
          <path
            id="svg-shadow-bottom"
            className="s0"
            fill="#7b7b7b"
            d="m286.9 1.6l-115.7 280.7h-15.4c36-87.5 79.5-193.2 115.7-280.7z"
          />
        </g>
        <g id="svg-fill">
          <path
            id="svg-fill-top"
            className="s1"
            fill="#fff"
            d="m74.8 145l58.6-141.5 1.1-3h3.2 3.7 3.1l1.2 3 52.2 125.6-2.4 5.8-5.3 12.7-50.7-122.2-57.9 140.3-1.2 2.9h-3.1-4.9-3.1l-1.2-2.9-65.5-158.7-2.7-6.5h7 5.1 3.1l1.2 3z"
          />
          <path
            id="svg-fill-middle"
            className="s1"
            fill="#fff"
            d="m251.8 259.1l58.6-141.5 1.2-2.9h3.1 3.7 3.1l1.2 2.9 58.6 141.5 58.4-141.5 1.2-2.9h3.1 5.3 6.9l-2.7 6.4-65.5 158.7-1.2 3h-3.1-4.9-3.1l-1.2-3-58-140.3-57.9 140.3-1.2 3h-3.1-4.9-3.1l-1.2-3-29.1-70.6 7.6-18.5z"
          />
          <path
            id="svg-fill-bottom"
            className="s1"
            fill="#fff"
            d="m284 1.6l-115.7 280.7h-15.5c36.1-87.5 79.6-193.2 115.7-280.7z"
          />
        </g>
      </g>
    </svg>
  );
};
