import React from 'react'
import { useState, useEffect } from 'react';
import { collection, doc, setDoc, getDoc, onSnapshot, query, where, getDocs } from 'firebase/firestore';
import { SlRadio, SlRadioGroup, SlAvatar } from "@shoelace-style/shoelace/dist/react";
import { auth, db } from '../Firebase';
import { useNavigate } from 'react-router-dom';
import { useCollection } from 'react-firebase-hooks/firestore';
import { getNextDayOfWeek } from '../components/utilities'
import _ from "lodash";
import ProgressSteps from '../components/ProgressSteps';


// --- USERS COLLECTION COMPONENT --- //
const UsersCollection = ({users, setUsers, nextWedParsed}) => {
  const usersRef = collection(db, "users");
  const [userCollection, loading, error] = useCollection(
    usersRef,
    { 
      snapshotListenOptions: { 
        includeMetadataChanges: true 
      },
    }
  );

  const exampleObject = {
    profilePic:
      "https://lh3.googleusercontent.com/a/AEdFTp4z6ETLmTg7VNVwxbN9-f91KnOCH5MBHnrOx1k7=s96-c",
    uid: "uvw9ZvLCK2gi7cM7ir6hir1xaAH3",
    lastLoginTime: {
      seconds: 1676958507,
      nanoseconds: 775000000,
    },
    email: "billthedev@gmail.com",
    name: "Bill Camarco",
    attendance: {
      1675696220000: true,
      1677042000000: true,
      1676437200000: false,
      1675832400000: false,
    },
  };
  
  useEffect(() => {
    if (!userCollection) { return; }
    let updatedUsers = {}
    
    userCollection.forEach((userDoc) => {
      const { email, name } = userDoc.data();
      if (email !== 'billthedev@gmail.com') {  
        const firstName = name.split(" ")[0].toLowerCase();
        updatedUsers[firstName] = userDoc.data();
      }
    })
      
    if (!_.isEqual(users, updatedUsers)) {
      setUsers(updatedUsers)
    }    
  }, [userCollection])
};


// --- QUORUM COMPONENT --- //
function Quorum({isAuth}) {
  // --- STATE --- //
  const [status, setStatus] = useState({
    jordan: null,
    joe: null,
    john: null,
    jeff: null,
    justin: null,
    bill: null,
  });
  const [users, setUsers] = useState(null);
  const [response, setResponse] = useState('');
  const currentUserStatus = status[isAuth?.displayName.split(" ")[0].toLowerCase()];

  // --- UTILITIES --- //
  const navigate = useNavigate();
  const uid = localStorage.getItem("uid");
  const docRef = doc(db, "users", uid);

  useEffect(() => {
    console.log("\n Users changed: ", users);
    updateStatus(users);
  }, [users]);

  const updateStatus = (users) => {
    for (const user in users) {
      if (user.email === 'billthedev@gmail.com') { continue; }
      const userData = users[user]
      const attendance = userData.attendance || {};

      if (attendance[nextWedParsed.toString()] === true && status[user] !== "in") {
        setStatus((prevStatus) => ({
          ...prevStatus,
          [user]: true,
        }));
      } else if (attendance[nextWedParsed.toString()] === false && status[user] !== "out") {
        setStatus((prevStatus) => ({
          ...prevStatus,
          [user]: false,
        }));
      }
    }
  };

  const handleAttendance = (e) => {
    // save form data to local state
    console.log("e.target.value", e.target.value);
    setResponse(e.target.value);
  };

  const handleSubmit = () => {
    // save form data to db
    console.log('Submitted rsvp response: ', response);
    createRsvp(response);
  }

  const createRsvp = async (bool) => {
    // TODO check if object exists in db first. Sometimes just creates an attendance obj alone.
    await setDoc(
      docRef,
      {
        attendance: {
          [nextWedParsed]: bool,
        },
      },
      { merge: true }
    ).then(getSnap());
  };

  const getSnap = async () => {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };
  
  // console.log(currentUserStatus);
  const today = new Date();
  const nextWed = getNextDayOfWeek(today, 3);
  const nextWedParsed = Date.parse(nextWed);
  console.log("Wed", Date.parse(nextWed));
  const memberTotal = _.size(status);
  const memberTrue = _.filter(status, (status) => status === true).length;
  const quorumStatus = (memberTrue > 3) 
    ? <span className="">Quorum Reached!</span> 
    : <span className="">Quorum Not Met</span>

  return (
    <div className="content quorum-page mb-5">
      <div className="container quorum-stats mb-3">
        <h1 className="d-block d-lg-none">Quorum</h1>
        <div className="row mt-3 card">
          <div className="card-header h2">
            <span className='me-3 fw-bold'>Status:</span>
            {(memberTrue > 3)
              ? <>
                  <span className='status-icon me-2'><i className="fa-solid fa-circle-check"></i></span>
                  <span>{`${memberTrue}-man`}</span>
                </>
              : <>
                  <span className='status-icon me-2'><i className="fa-solid fa-circle-xmark"></i></span>
                  <span>{`${memberTrue} isn't enough...`}</span>
                </>
            }
            {/* {quorumStatus} */}
          </div>
          <div className="card-body row">
            <div className="col-md-4">
              <div className="card-text h5">
                <span>Next Session:</span>
                <span>{nextWed}</span>
              </div>
              <div className="card-text h5">
                <span>Confirmed:</span>
                <span>{memberTrue}/{memberTotal}</span>
              </div>
              <button
                type="button"
                className="btn btn-primary d-block fw-bold"
                id="rsvp-btn"
                data-bs-toggle="modal"
                data-bs-target="#quorumModal"
              >
                {(currentUserStatus === null) ? "RSVP" : "Update RSVP"}
              </button>
            </div>
            {/* Set Your Status */}
            <div className="col-md-8 progress-wrapper">
              <ProgressSteps
                numTrue={memberTrue}
                total={memberTotal}
              />
              <h4>{quorumStatus}</h4>
            </div>
          </div>
        </div>
      </div>


      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="quorumModal"
        tabIndex="-1"
        aria-labelledby="quorumModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title fs-4" id="quorumModalLabel">
                {`Whisky Wednesday, ${nextWed}`}
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body ">
              {/* <h3>{`Next Wednesday parsed: ${nextWedParsed}`}</h3> */}
              <SlRadioGroup
                name="a"
                className="text-start"
                onSlInput={handleAttendance}
              >
                <SlRadio value={true}>
                  <div className="h5">IN</div>
                </SlRadio>
                <SlRadio value={false}>
                  <div className="h5">OUT</div>
                </SlRadio>
              </SlRadioGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Collection Test Render */}
      <UsersCollection
        users={users}
        setUsers={setUsers}
        nextWedParsed={nextWedParsed}
      />
      
      {/* Group Status Cards */}
      {users && (
        <div className="quorum-users container p-0">
          <div className="grid gap-3">
            {/* {console.log('Users: ', users)} */}
            {Object.values(users).map((user, index) => {
              let userStatus = _.isBoolean(user.attendance[nextWedParsed.toString()]) ? user.attendance[nextWedParsed.toString()] : null
              return (
                <UserCard 
                  key={index} 
                  name={user.name} 
                  status={userStatus} 
                  avatarImg={user.profilePic}
                  isCurrentUser={isAuth?.uid === user.uid}
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  );
}

const UserCard = ({ name, status, avatarImg, isCurrentUser }) => {
  let statusClassName = '';
  let statusText = '';
  let borderClassName = '';
  if (status === null) {
    // statusText = 'Pending';
    statusText = <span className="badge bg-warning">PENDING</span>;
    statusClassName = 'status-btn-pending';
    borderClassName = 'border-warning';
  } else if (status === true) {
    // statusText = 'In';
    statusText = <span className="badge bg-success">IN</span>;
    statusClassName = 'status-btn-in';
    borderClassName = 'border-success';
  } else {
    // statusText = 'Out';
    statusText = <span className="badge bg-danger">OUT</span>;
    statusClassName = 'status-btn-out';
    borderClassName = 'border-danger';
  }

  let role = '';
  switch (name) {
    case 'Bill Camarco':
      role = 'Defense Against the Dark Arts';
      break;
    case 'Jeff Sokol':
      role = 'Founder (almost)';
      break;
    default:
      role = 'Founder';
  }

  let currentUserCard = document.querySelector('.current-user');
  if (currentUserCard) {
    currentUserCard.setAttribute('data-bs-toggle', 'modal');
    currentUserCard.setAttribute('data-bs-target', '#quorumModal');
  }

  return (
    <div 
      className={`card ${borderClassName} border border-3${isCurrentUser ? ' current-user' : ''}`}
    >
      <div className="row gx-0">
        <div className="col-md-4">
          <img
            src={avatarImg}
            alt={`Image of the infamous ${name}`}
            className="avatar-img rounded-circle shadow-lg mx-auto my-3 border border-3"
          />
        </div>
        <div className="col-md-8">
          <div className="card-body text-start">
            <p className="card-title h4">{name}</p>
            <p className="small text-muted h5">{role}</p>
            <div className="card-status h5 d-flex align-items-center justify-content-between w-100">
              {statusText}
              {isCurrentUser && <>
                <span className="small edit-msg">
                  <i className="fa-solid fa-pen-to-square me-2"></i>Edit RSVP
                </span>
              </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quorum