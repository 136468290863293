import React from 'react';

const ProgressSteps = ({numTrue, total}) => {
  // const qStatus = (numTrue > 3) ? 'Quorum met!' : 'Quorum not met.';
  const steps = [];
  const widthStep = `${100 / (total)}%`
  const widthGlow = `${(100 / total)*1.5}%` 

  for (let i = 0; i < total; i++) {
    const isQuorum = i === 3;
    const isFilled = i < numTrue;
    const stepLineClass = isFilled ? 'step-line filled' : 'step-line';
    let stepBubbleClass = isFilled ? 'step-bubble filled' : 'step-bubble';

    steps.push(
      <div 
        className={`progress-step${isQuorum ? ' step-glow' : ''}`} 
        key={i} 
        style={{width: widthStep}}
      >
        {i !== total && <span className={stepLineClass}></span>}
        <div className={stepBubbleClass}>
          <span>{i + 1}</span>
        </div>
        {/* {isQuorum && <div id="progress-msg">Quorum</div>} */}
      </div>
    )
  }

  return (
    <div id="progress-bar" className="container mx-auto my-auto">
      <div className="progress-steps">{steps}</div>
    </div>
  )
}

export default ProgressSteps;
