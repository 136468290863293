import React, { useState, useEffect, useRef } from 'react';
import OceanVoyage from '../assets/audio/ocean_voyage_ww.mp3';
import OceanWhiskyImage from '../assets/img/whisky-ocean-01.png';
import { checkWednesday } from './utilities';

// https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks

const AudioPlayer = () => {
  const tracks = [
    {
      title: 'Ocean Voyage',
      artist: 'Samsung',
      audioSrc: OceanVoyage,
      image: OceanWhiskyImage,
      color: '#BFC0C0',
    },
  ]
	// State
  const [trackIndex, setTrackIndex] = useState(0);
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // Destructure for conciseness
	const { title, artist, audioSrc, image, color} = tracks[trackIndex];

	// Refs
  const audioRef = useRef(new Audio(audioSrc));
  audioRef.current.loop = true; // loop by default
  const intervalRef = useRef();
  const isReady = useRef(false);

  let isWednesday = checkWednesday();

	// Destructure for conciseness
	const { duration } = audioRef.current;

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    audioRef.current.addEventListener('ended', () => {
      setIsPlaying(false);
      setTrackProgress(0);
      clearInterval(intervalRef.current);
    });

    // Pause the audio and clear the interval when the component is unmounted
    return () => {
      audioRef.current.removeEventListener('ended', () => {});
      clearInterval(intervalRef.current);
      audioRef.current.pause();
    };
  }, []);

  const startTimer = () => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        setIsPlaying(false);
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, 1000);
  };

	return ( 
    <div className="audio-player">
      <h2 className="mt-2">{isWednesday ? "It\'s Wednesday my dudes!" : ""}</h2>
			<div className="track-info">
			  <img
			    className="artwork"
			    src={image}
			    alt={`track artwork for ${title} by ${artist}`}
			  />
		    <h2 className="title">{title}</h2>
        <h3 className="artist">{artist}</h3>
        <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => {
            audioRef.current.currentTime = e.target.value;
            setTrackProgress(audioRef.current.currentTime);
          }}
        />
        <AudioControls 
          isPlaying={isPlaying}
          onPlayPauseClick={setIsPlaying}
        />
			</div>
		</div> 
  );
}

const AudioControls = ({
isPlaying,
onPlayPauseClick,
}) => ( 
  <div className="audio-controls">
  {isPlaying ? (
    <button
      type="button"
      className="pause"
      onClick={() => onPlayPauseClick(false)}
      aria-label="Pause"
    >
      <i className="fa-solid fa-circle-pause"></i>
    </button>
  ) : (
    <button
      type="button"
      className="play"
      onClick={() => onPlayPauseClick(true)}
      aria-label="Play"
    >
      <i className="fa-solid fa-circle-play"></i>
    </button>
  )}
</div>
)

export default AudioPlayer;